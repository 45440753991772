<template>
    <div>
        <div class="top-header blackBG border-bottom">
            <div class="marquee border-top-0 border-bottom">
                <banner-slider></banner-slider>
            </div>
        </div>
        <section class="bg-white blockElement innerPage-banner">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="breadCrum d-flex align-items-center justify-content-between flex-wrap">
                            <div class="bdPage">
                                <h2>Economic Calendar</h2>
                                <nav aria-label="breadcrumb mt-2">
                                    <ol class="breadcrumb mb-0">
                                        <li class="breadcrumb-item">
                                            <router-link class="green" to="/">Home</router-link>
                                        </li>
                                        <li class="breadcrumb-item active" aria-current="page">
                                            Tools
                                        </li>
                                        <li class="breadcrumb-item active" aria-current="page">
                                            Economic Calendar
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                            <div class="rightSearch invTab d-flex align-items-center">
                                <router-link to="/economy-overview" class="grd_btn me-0 darkBG">Economy Overview
                                </router-link>
                                <!-- <form class="searchBar">
                                    <div class="expandMobile form-group position-relative inputIcon mb-0">
                                        <input type="text" class="form-control" placeholder="Search.." name="" /> <a class="searchButton" href="#"><vue-feather type="search"></vue-feather> </a>
                                    </div>
                                </form> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="blockElement bg-white economicCalender space-footer">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div
                            class="calendarFilter position-relative d-flex align-items-center justify-content-between flex-wrap mb-3">
                            <div class="d-flex align-items-center">
                                <div class="addFilter me-2">
                                    <a @click="() => toggleFilter('country')"
                                        class="border-btn d-flex align-items-center" href="javascript:void(0)">
                                        <img src="assets/images/filter.svg" alt="filter" title="Add Filter"
                                            class="me-md-2" />
                                        <span>Add Filters</span>
                                    </a>
                                    <div class="custom-dropdown" :class="filterOpen.country ? 'active' : ''">
                                        <div
                                            class="filterHeader border-bottom p-3 d-flex align-items-center justify-content-between drop-1199">
                                            <div class="position-relative">
                                                <a @click.prevent="dropdownMenu2 = !dropdownMenu2"
                                                    href="javascript:void(0)"
                                                    class="pt-0 border-btn d-inline-flex align-items-center justify-content-between d-xl-none ms-2">
                                                    All
                                                    <i class="ms-3 fa fa-sort-desc" aria-hidden="true"></i>
                                                </a>
                                                <ul class="dropdown_menu_animated onMbile pad-0 selectTab px-2 d-md-flex align-items-center mb-0"
                                                    :class="dropdownMenu2 ? 'show' : ''">
                                                    <li v-for="option of countryFilterOptions">
                                                        <a href="javascript:void(0)" :class="{
                                                          active: currentCountryFilter == option,
                                                        }" @click="() => selectCountriesFromTab(option)">{{ option }}</a>
                                                    </li>
                                                </ul>
                                            </div>
                                            <a @click.prevent="filterOpen.country = false" href="javascript:void(0)"
                                                class="clearAll d-inline-flex flex-shrink-0">
                                                <vue-feather type="x" size="20"></vue-feather>
                                            </a>
                                        </div>
                                        <!-- <form class="searchBar"> <div class="form-group position-relative inputIcon mb-0"> <input type="text" class="form-control" placeholder="Search.." name=""/> <a class="searchButton" href="#" ><vue-feather type="search"></vue-feather> </a> </div></form> -->
                                        <div class="filter-body p-3 border-bottom">
                                            <ul
                                                class="d-flex align-items-center flex-wrap country scrollbarFilter flex-wrap mb-0">
                                                <li v-for="country of allCountries" class="active">
                                                    <label class="customCheckbox text-start">
                                                        {{ country
                                                        }}<input type="checkbox" @click="() => toggleSelection(country)"
                                                            :checked="inSelectedCountries(country)" />
                                                        <span class="checkmark"></span>
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                        <div
                                            class="filter-footer p-3 d-flex align-items-center justify-content-between flex-wrap">
                                            <label class="customCheckbox">Save setting in my filter <input
                                                    type="checkbox" />
                                                <span class="checkmark"></span>
                                            </label>
                                            <div class="d-flex align-items-center">
                                                <a href="javascript:void(0)" @click="() => clearCountries()"
                                                    class="grd_btn redBG me-3">Clear</a>
                                                <a @click="() => getCalendarList()" class="grd_btn">Apply Filter</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="addFilter me-2">
                                    <a @click="() => toggleFilter('impact')"
                                        class="border-btn d-flex align-items-center" href="javascript:void(0)">
                                        <span class="d-flex align-items-center impactBtn" :class="{
                                          oneStar: filters.impact == 1,
                                          twoStar: filters.impact == 2,
                                          threeStar: filters.impact == 3,
                                        }">
                                            <vue-feather v-if="filters.impact < 1" type="star" size="18px"
                                                class="me-md-2" />
                                            <vue-feather v-if="filters.impact >= 1" class="filed active" type="star"
                                                size="18px"></vue-feather>
                                            <vue-feather v-if="filters.impact >= 2" class="filed active" type="star"
                                                size="18px"></vue-feather>
                                            <vue-feather v-if="filters.impact >= 3" class="filed active" type="star"
                                                size="18px"></vue-feather>
                                        </span>
                                        <span>Impact</span>
                                    </a>
                                    <div class="custom-dropdown impact"
                                        :class="filterOpen.impact == true ? 'active' : ''">
                                        <div class="filter-importance p-3 border-bottom">
                                            <div class="">
                                                <label class="customCheckbox">All
                                                    <input type="radio" checked="checked" name="radio" value="0"
                                                        v-model="filters.impact" />
                                                    <span class="checkmark"></span>
                                                </label>
                                                <label class="customCheckbox">
                                                    <vue-feather type="star" size="20px"></vue-feather>
                                                    <input type="radio" name="radio" value="1"
                                                        v-model="filters.impact" />
                                                    <span class="checkmark"></span>
                                                </label>
                                                <label class="customCheckbox d-flex align-items-center">
                                                    <vue-feather class="me-1" type="star" size="20px"></vue-feather>
                                                    <vue-feather type="star" size="20px"></vue-feather>
                                                    <input type="radio" name="radio" value="2"
                                                        v-model="filters.impact" />
                                                    <span class="checkmark"></span>
                                                </label>
                                                <label class="customCheckbox d-flex align-items-center">
                                                    <vue-feather class="me-1" type="star" size="20px"></vue-feather>
                                                    <vue-feather class="me-1" type="star" size="20px"></vue-feather>
                                                    <vue-feather type="star" size="20px"></vue-feather>
                                                    <input type="radio" name="radio" value="3"
                                                        v-model="filters.impact" />
                                                    <span class="checkmark"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="addFilter">
                                    <a @click="() => toggleFilter('timezone')"
                                        class="border-btn d-flex align-items-center" href="javascript:void(0)">
                                        <vue-feather class="me-3 me-md-0" size="15px" type="clock"></vue-feather>
                                        <span class="mx-2">{{
                                        getTimezoneSelected(selectedTimeZone)
                                        }}</span>
                                        <vue-feather size="18px" type="chevron-down"></vue-feather>
                                    </a>
                                    <div class="custom-dropdown max-360" :class="filterOpen.timezone ? 'active' : ''">
                                        <!-- <form class="searchBar"> <div class="form-group position-relative inputIcon mb-0"> <input type="text" class="form-control" placeholder="Search.." name=""/> <a class="searchButton" href="#" ><vue-feather type="search"></vue-feather> </a> </div></form> -->
                                        <div class="filter-gmt p-2">
                                            <ul class="timeZone scrollbarFilter mb-0">
                                                <li v-for="tz of static_vars.timezonesCalendar" :key="tz.value">
                                                    <a href="javascript:void(0)"
                                                        @click.prevent="selectedTimeZone = tz.value">{{ tz.label }}</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex align-items-center position-relative drop-1199">
                                <div class="fromTo d-flex align-items-center border-btn py-0"
                                    v-if="customCalendarDate[0] && customCalendarDate[1]">
                                    <p class="mb-0 medium">From</p>
                                    <p class="mb-0 ms-1">
                                        {{ formatCustomDate(customCalendarDate[0]) }}
                                    </p>
                                    <p class="mb-0 ms-2 medium">To</p>
                                    <p class="mb-0 ms-1">
                                        {{ formatCustomDate(customCalendarDate[1]) }}
                                    </p>
                                </div>
                                <a href="javascript:void(0)" class="closeBtn"
                                    v-if="customCalendarDate[0] && customCalendarDate[1]" @click="
                                      customCalendarDate = [];
                                      dateChangeActive = 'recent';
                                    ">
                                    <vue-feather size="16" type="x"></vue-feather>
                                </a>
                                <div class="position-relative">
                                    <a @click.prevent="dropdownMenu = !dropdownMenu" href="javascript:void(0)"
                                        class="pt-0 border-btn d-flex align-items-center justify-content-between ms-2 filter-dropdown">
                                        {{ filterJson[dateChangeActive]
                                        }}<i class="ms-3 fa fa-sort-desc" aria-hidden="true"></i>
                                    </a>
                                    <ul class="dropdown_menu_animated" :class="dropdownMenu ? 'show' : ''">
                                        <li>
                                            <a class="border-end-0 py-2"
                                                :class="{ active: dateChangeActive == 'recent' }"
                                                @click="() => changeDate('recent')">Recent</a>
                                        </li>
                                        <!-- <li>
                                            <a class="border-end-0 py-2"
                                                :class="{ active: dateChangeActive == 'today' }"
                                                @click="() => changeDate('today')">Today</a>
                                        </li> -->
                                        <li>
                                            <a class="border-end-0 py-2"
                                                :class="{ active: dateChangeActive == 'tomorrow' }"
                                                @click="() => changeDate('tomorrow')">Tomorrow</a>
                                        </li>
                                        <li>
                                            <a class="border-end-0 py-2" :class="{ active: dateChangeActive == 'week' }"
                                                @click="() => changeDate('week')">This Week</a>
                                        </li>
                                        <li>
                                            <a class="border-end-0 py-2"
                                                :class="{ active: dateChangeActive == 'nextWeek' }"
                                                @click="() => changeDate('nextWeek')">Next Week</a>
                                        </li>
                                        <li>
                                            <a class="border-end-0 py-2"
                                                :class="{ active: dateChangeActive == 'nextMonth' }"
                                                @click="() => changeDate('nextMonth')">Next Month</a>
                                        </li>
                                        <li>
                                            <a class="border-end-0 py-2"
                                                :class="{ active: dateChangeActive == 'yesterday' }"
                                                @click="() => changeDate('yesterday')">Yesterday</a>
                                        </li>
                                        <li>
                                            <a class="border-end-0 py-2"
                                                :class="{ active: dateChangeActive == 'prevWeek' }"
                                                @click="() => changeDate('prevWeek')">Previous Week</a>
                                        </li>
                                        <li>
                                            <a class="border-end-0 py-2"
                                                :class="{ active: dateChangeActive == 'prevMonth' }"
                                                @click="() => changeDate('prevMonth')">Previous Month</a>
                                        </li>
                                        <li>
                                            <a class="border-end-0 py-2 custom-date"
                                                :class="{ active: dateChangeActive == 'custom' }" @click="
                                                  () => {
                                                    dateChangeActive = 'custom';
                                                    toggleCalendar();
                                                  }
                                                ">Custom</a>
                                        </li>
                                    </ul>
                                </div>
                                <!-- <a href="javascript:void(0)" class="ms-2 d-flex border-btn viewCalander py-0" @click.prevent="()=> toggleCalendar()"> <vue-feather size="18px" type="calendar"></vue-feather> </a> -->
                                <Datepicker :range="true" v-model="customCalendarDate" :multiCalendars="true" inline
                                    autoApply :multiCalendarsSolo="true" :enableTimePicker="false" ref="datepicker"
                                    v-if="showCalendar" @update:modelValue="handleDate" />
                            </div>
                        </div>

                        <div class="table-responsive echoCalendar border">
                            <table v-if="dateAdjustedEvents.length" class="table table-sm m-0 border-0 fixed-layout">
                                <thead>
                                    <tr>
                                        <th width="100">Time</th>
                                        <th width="200">&nbsp;</th>
                                        <th width="300">&nbsp;</th>
                                        <th>Actual</th>
                                        <th>Forecast</th>
                                        <th>Consensus</th>
                                        <th>Previous</th>
                                        <!-- <th>Impact</th> -->
                                        <th align="right">
                                            &nbsp;
                                            <!-- <a class="notification d-flex" href="javascript:void(0)"><vue-feather type="bell"></vue-feather></a> -->
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(row, iOuter) of dateAdjustedEvents">
                                        <td colspan="8" class="border-0 p-0">
                                            <table class="table innerTable">
                                                <tr>
                                                    <table class="table innerTable subTable">
                                                        <tbody>
                                                            <tr>
                                                                <td class="dateBG" colspan="8">
                                                                    <span
                                                                        class="d-flex align-items-center justify-content-between min-70">
                                                                        <h6 class="f-17 mb-0">
                                                                            {{
                                                                            COMMON.formatDate(row.date, "Do MMMM")
                                                                            }}
                                                                        </h6>
                                                                        <span class="greenBG totalCurrency">{{
                                                                        row.items.length
                                                                        }}</span>
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                            <tr v-for="(item, index) of row.items">
                                                                <td class="border-0 p-0" colspan="8">
                                                                    <table class="table fixed-layout nowrap">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td width="100" :class="
                                                                                  item.importance == 2
                                                                                    ? 'medium'
                                                                                    : item.importance == 3
                                                                                    ? 'redBG'
                                                                                    : ''
                                                                                ">
                                                                                    <p class="mb-0">
                                                                                        {{
                                                                                        COMMON.formatDate(
                                                                                        item.date,
                                                                                        "hh:mm a"
                                                                                        )
                                                                                        }}
                                                                                    </p>
                                                                                </td>
                                                                                <td width="200" class="medium">
                                                                                    <span
                                                                                        class="d-flex align-items-center p-0">
                                                                                        <img width="20" class="me-2"
                                                                                            :src="
                                                                                              'assets/images/country_flag/' +
                                                                                              item.country.toLowerCase() +
                                                                                              '.webp'
                                                                                            " alt="Flag" :title="item.country" />
                                                                                        {{ item.country }}
                                                                                    </span>
                                                                                </td>
                                                                                <td width="300 nowrap">
                                                                                    {{ item.event }}
                                                                                </td>
                                                                                <td class="red">
                                                                                    {{ item.actual ? item.actual : "-"
                                                                                    }}
                                                                                </td>
                                                                                <td>
                                                                                    {{
                                                                                    item.teForecast
                                                                                    ? item.teForecast
                                                                                    : "-"
                                                                                    }}
                                                                                </td>
                                                                                <td>
                                                                                    {{
                                                                                    item.forecast ? item.forecast : "-"
                                                                                    }}
                                                                                </td>
                                                                                <td>
                                                                                    {{
                                                                                    item.previous ? item.previous : "-"
                                                                                    }}
                                                                                </td>
                                                                                <!-- <td>
                                                                                    <span class="d-flex align-items-center p-0">
                                                                                        <vue-feather v-if="item.importance >=1" class="filed active" type="star" size="18px"></vue-feather>
                                                                                        <vue-feather v-if="item.importance >=2" class="filed active" type="star" size="18px"></vue-feather>
                                                                                        <vue-feather v-if="item.importance >=3" class="filed active" type="star" size="18px"></vue-feather>
                                                                                    </span>
                                                                                </td> -->
                                                                                <td class="position-relative">
                                                                                    <div
                                                                                        class="d-inline-flex align-items-center justify-content-end w-100">
                                                                                        <div class="notifyAlert me-3">
                                                                                            <!-- <a class="notification d-flex"
                                                                                                @click.prevent="
                                                                                                  showAlertBox(iOuter, index)
                                                                                                " href="javascript:void(0)">
                                                                                                <vue-feather
                                                                                                    type="bell">
                                                                                                </vue-feather>
                                                                                            </a> -->
                                                                                            <div v-if="
                                                                                              modalIndexInner == index &&
                                                                                              modalIndexOuter == iOuter
                                                                                            " class="custom-dropdown createAlert max-360" :class="
                                                                                                  modalIndexInner == index &&
                                                                                                  modalIndexOuter == iOuter
                                                                                                    ? 'active'
                                                                                                    : ''
                                                                                                ">
                                                                                                <div
                                                                                                    class="filterHeader border-bottom mb-2 p-3 d-flex align-items-center justify-content-between">
                                                                                                    <h5 class="mb-0">
                                                                                                        Create Alert
                                                                                                    </h5>
                                                                                                    <a @click.prevent="
                                                                                                      showAlertBox(
                                                                                                        iOuter,
                                                                                                        index
                                                                                                      )
                                                                                                    " href="javascript:void(0)" class="clearAll flex-shrink-0">
                                                                                                        <vue-feather
                                                                                                            type="x"
                                                                                                            size="20">
                                                                                                        </vue-feather>
                                                                                                    </a>
                                                                                                </div>
                                                                                                <div
                                                                                                    class="filter-gmt p-3 border-bottom">
                                                                                                    <div
                                                                                                        class="form-group">
                                                                                                        <h6
                                                                                                            class="mb-3">
                                                                                                            Notification
                                                                                                            Interval
                                                                                                        </h6>
                                                                                                        <select
                                                                                                            class="form-control form-select">
                                                                                                            <option>
                                                                                                                02:00
                                                                                                                Hrs
                                                                                                            </option>
                                                                                                            <option>
                                                                                                                03:00
                                                                                                                Hrs
                                                                                                            </option>
                                                                                                            <option>
                                                                                                                04:00
                                                                                                                Hrs
                                                                                                            </option>
                                                                                                            <option>
                                                                                                                05:00
                                                                                                                Hrs
                                                                                                            </option>
                                                                                                        </select>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        class="form-group">
                                                                                                        <h6
                                                                                                            class="mb-3">
                                                                                                            Frequency
                                                                                                        </h6>
                                                                                                        <div
                                                                                                            class="d-flex align-items-center">
                                                                                                            <label
                                                                                                                class="customCheckbox me-4">Once
                                                                                                                <input
                                                                                                                    type="radio"
                                                                                                                    name="radio" />
                                                                                                                <span
                                                                                                                    class="checkmark"></span>
                                                                                                            </label>
                                                                                                            <label
                                                                                                                class="customCheckbox">Recurring
                                                                                                                <input
                                                                                                                    type="radio"
                                                                                                                    name="radio" />
                                                                                                                <span
                                                                                                                    class="checkmark"></span>
                                                                                                            </label>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        class="form-group fullWidth">
                                                                                                        <h6
                                                                                                            class="mb-3">
                                                                                                            Delivery
                                                                                                            Method
                                                                                                        </h6>
                                                                                                        <label
                                                                                                            class="customCheckbox">Website
                                                                                                            popup
                                                                                                            <input
                                                                                                                type="radio"
                                                                                                                name="radio" />
                                                                                                            <span
                                                                                                                class="checkmark"></span>
                                                                                                        </label>
                                                                                                        <label
                                                                                                            class="customCheckbox">Mobile
                                                                                                            App
                                                                                                            notifications
                                                                                                            <input
                                                                                                                type="radio"
                                                                                                                name="radio" />
                                                                                                            <span
                                                                                                                class="checkmark"></span>
                                                                                                        </label>
                                                                                                        <label
                                                                                                            class="customCheckbox">Email
                                                                                                            Notifications
                                                                                                            <input
                                                                                                                type="radio"
                                                                                                                name="radio" />
                                                                                                            <span
                                                                                                                class="checkmark"></span>
                                                                                                        </label>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div
                                                                                                    class="submitBtn text-end px-3 py-4">
                                                                                                    <a href="javascript:void(0)"
                                                                                                        class="grd_btn">Create
                                                                                                        Alert</a>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <a class="openTable circleIcon"
                                                                                            @click="
                                                                                              () =>
                                                                                                toggleInner(
                                                                                                  iOuter,
                                                                                                  index,
                                                                                                  item.ticker
                                                                                                )
                                                                                                                                        " :class="
                                                                                            indexInner == index
                                                                                              ? 'active'
                                                                                              : ''
                                                                                          " href="javascript:void(0)">
                                                                                            <vue-feather :type="
                                                                                              indexInner == index
                                                                                                ? 'minus'
                                                                                                : 'plus'
                                                                                            "></vue-feather>
                                                                                        </a>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                            <tr class="border-0" v-if="
                                                                              indexInner == index &&
                                                                              indexOuter == iOuter
                                                                            ">
                                                                                <td colspan="8" class="word-wrap">
                                                                                    <div
                                                                                        class="active showTable row mx-0 px-2 py-3 border-0">
                                                                                        <div class="col-4" v-if="
                                                                                          store.calendarTickerData
                                                                                            .definition
                                                                                        ">
                                                                                            <div>
                                                                                                <!-- <h6 class="mb-3">{{store.calendarTickerData .lastnews[0].title}}</h6> -->
                                                                                                <p>
                                                                                                    {{
                                                                                                    store.calendarTickerData
                                                                                                    .definition || ""
                                                                                                    }}
                                                                                                </p>
                                                                                            </div>
                                                                                            <!-- <no-record v-else></no-record> -->
                                                                                        </div>
                                                                                        <div :class="
                                                                                          store.calendarTickerData
                                                                                            .definition
                                                                                            ? 'col-8'
                                                                                            : 'col-12'
                                                                                        ">
                                                                                            <div v-show="
                                                                                              store.calendarTickerData
                                                                                                .chartdata &&
                                                                                              store.calendarTickerData
                                                                                                .chartdata.length
                                                                                            " style="
                                                                                                  width: 100%;
                                                                                                  height: 300px;
                                                                                                ">
                                                                                                <div style="
                                                                                                width: 100%;
                                                                                                height: 300px;
                                                                                              " id="tickerChart"></div>
                                                                                            </div>
                                                                                            <no-record v-show="
                                                                                              !store.calendarTickerData
                                                                                                .chartdata ||
                                                                                              (store.calendarTickerData
                                                                                                .chartdata &&
                                                                                                !store.calendarTickerData
                                                                                                  .chartdata.length)
                                                                                            "></no-record>
                                                                                        </div>
                                                                                        <!-- <div class="col-4" :class="
                                                                                          submitVote ||
                                                                                          dateAdjustedEvents[indexOuter]
                                                                                            .items[indexInner].actual !=
                                                                                            ''
                                                                                            ? 'd-none'
                                                                                            : 'd-block'
                                                                                        ">
                                                                                            <h6 class="f-17 p-0 mb-3">
                                                                                                Share your
                                                                                                <span
                                                                                                    class="green">Thoughts</span>
                                                                                            </h6>
                                                                                            <div
                                                                                                class="d-flex align-items-center justify-content-between flex-wrap vote mb-2 p-0 mb-4">
                                                                                                <label
                                                                                                    class="customCheckbox w-100 mb-4">Vote
                                                                                                    Impact on GBP
                                                                                                    <input
                                                                                                        type="checkbox" />
                                                                                                    <span
                                                                                                        class="checkmark"></span>
                                                                                                </label>
                                                                                                <label
                                                                                                    class="customCheckbox neutral active">
                                                                                                    Positive
                                                                                                    <input type="radio"
                                                                                                        name="radio" />
                                                                                                    <span
                                                                                                        class="selectStar">
                                                                                                        <vue-feather
                                                                                                            type="star"
                                                                                                            size="20px">
                                                                                                        </vue-feather>
                                                                                                    </span>
                                                                                                </label>
                                                                                                <label
                                                                                                    class="customCheckbox neutral">
                                                                                                    Negative
                                                                                                    <input type="radio"
                                                                                                        name="radio" />
                                                                                                    <span
                                                                                                        class="selectStar">
                                                                                                        <vue-feather
                                                                                                            type="star"
                                                                                                            size="20px">
                                                                                                        </vue-feather>
                                                                                                    </span>
                                                                                                </label>
                                                                                                <label
                                                                                                    class="customCheckbox neutral">
                                                                                                    Neutral
                                                                                                    <input type="radio"
                                                                                                        name="radio" />
                                                                                                    <span
                                                                                                        class="selectStar">
                                                                                                        <vue-feather
                                                                                                            type="star"
                                                                                                            size="20px">
                                                                                                        </vue-feather>
                                                                                                    </span>
                                                                                                </label>
                                                                                            </div>
                                                                                            <div class="vote p-0 mb-4">
                                                                                                <label
                                                                                                    class="customCheckbox w-100 mb-4">Mark
                                                                                                    your expectations
                                                                                                    <input
                                                                                                        type="checkbox" />
                                                                                                    <span
                                                                                                        class="checkmark"></span>
                                                                                                </label>
                                                                                                <div
                                                                                                    class="range-slider position-relative">
                                                                                                    <vue3-slider
                                                                                                        v-model="slider.value"
                                                                                                        color="#16C784"
                                                                                                        track-color="#F5F6F7"
                                                                                                        width="100%"
                                                                                                        :height="14"
                                                                                                        :tooltip="true"
                                                                                                        tooltipText="%v"
                                                                                                        tooltipColor="#16C784"
                                                                                                        :step="step"
                                                                                                        alwaysShowHandle
                                                                                                        :min="slider.low"
                                                                                                        :max="slider.high" />
                                                                                                    <div class="d-flex align-items-center justify-content-between mt-3"
                                                                                                        v-if="
                                                                                                          slider.low && slider.high
                                                                                                        ">
                                                                                                        <span
                                                                                                            class="range-slider__value">{{
                                                                                                            getFormattedPrice(
                                                                                                            slider.low
                                                                                                            )
                                                                                                            }}</span>
                                                                                                        <span
                                                                                                            class="slider__value green">{{
                                                                                                            getFormattedPrice(
                                                                                                            slider.high
                                                                                                            )
                                                                                                            }}</span>
                                                                                                    </div>
                                                                                                    <a class="arrow left-arrow"
                                                                                                        href="javascript:void(0)"
                                                                                                        @click="
                                                                                                          () =>
                                                                                                            setSliderValues('add')
                                                                                                        ">
                                                                                                        <vue-feather
                                                                                                            type="plus"
                                                                                                            size="20px">
                                                                                                        </vue-feather>
                                                                                                    </a>
                                                                                                    <a class="arrow right-arrow"
                                                                                                        href="javascript:void(0)"
                                                                                                        @click.prevent="
                                                                                                          setSliderValues('sub')
                                                                                                        ">
                                                                                                        <vue-feather
                                                                                                            type="minus"
                                                                                                            size="20px">
                                                                                                        </vue-feather>
                                                                                                    </a>
                                                                                                </div>
                                                                                            </div>
                                                                                            <a @click.prevent="
                                                                                              submitVote = !submitVote
                                                                                            " href="javascript:void(0)" class="grd_btn">Submit your vote</a>
                                                                                        </div> -->
                                                                                        <!-- <div class="col-4" :class="
                                                                                          submitVote ||
                                                                                          dateAdjustedEvents[indexOuter]
                                                                                            .items[indexInner].actual !=
                                                                                            ''
                                                                                            ? 'd-block'
                                                                                            : 'd-none'
                                                                                        ">
                                                                                            <div class="border">
                                                                                                <div
                                                                                                    class="border-bottom p-2 mb-3 text-center">
                                                                                                    <h6
                                                                                                        class="f-17 mb-0">
                                                                                                        Public
                                                                                                        <span
                                                                                                            class="green">Opinion</span>
                                                                                                    </h6>
                                                                                                    <p class="mb-0">
                                                                                                        Based on 200
                                                                                                        reviews
                                                                                                    </p>
                                                                                                </div>
                                                                                                <div
                                                                                                    class="chartBar mb-4">
                                                                                                    <img class="contain"
                                                                                                        src="assets/images/chart/creat.png"
                                                                                                        alt="Chart"
                                                                                                        title="Public Opinion Graph" />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div> -->
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import { useMainStore } from "@/store";
import bannerslider from "../../components/shared/banner_slider";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import moment from "moment";
import $ from "jquery";

export default {
    setup() {
        const store = useMainStore();
        return { store };
    },
    data() {
        return {
            countryFilterOptions: [
                "All",
                "Major",
                "Asia",
                "America",
                "Europe",
                "Australia",
            ],
            currentCountryFilter: "Major",
            selectedCountries: [],
            addFilter: false,
            addImpactFilter: false,
            addTimeZone: false,
            selectedTimeZone: 0,
            openTable: false,
            addAlert: false,
            dropdownMenu: false,
            dropdownMenu2: false,
            showCalendar: false,
            submitVote: false,
            modalIndexInner: -1,
            modalIndexOuter: -1,
            indexInner: -1,
            sliderValue: 0,
            slider: { value: 0, low: 0, high: 0 },
            step: 0,
            caldata: {},
            filterOpen: {
                timezone: false,
                impact: false,
                country: false,
            },
            filterJson: {
                recent: "Recent",
                today: "Today",
                tomorrow: "Tomorrow",
                week: "This Week",
                nextWeek: "Next Week",
                nextMonth: "Next Month",
                yesterday: "Yesterday",
                prevWeek: "Previous Week",
                prevMonth: "Previous Month",
                custom: "Custom",
            },
            dateChangeActive: "recent",
            indexOuter: 0,
            customCalendarDate: [],
            filters: {
                impact: 0,
                countries: [],
                dates: {
                    from: moment().subtract(1, "day").format("YYYY-MM-DD"),
                    to: moment().add(10, 'day').format("YYYY-MM-DD"),
                },
            },
        };
    },
    watch: {
        "filters.impact"(nVal, oVal) {
            this.getCalendarList();
        },
    },
    computed: {
        allCountries() {
            let arr = [];
            for (let key in this.static_vars.economicCalendarCountries) {
                this.static_vars.economicCalendarCountries[key].map((i) => {
                    arr.push(i);
                });
            }
            return [...new Set(arr)].sort((a, b) => a.localeCompare(b));
        },
        dateAdjustedEvents() {
            if (Object.keys(this.store.calendarData).length) {
                let temp = [],
                    allEvents = [];
                for (let key in this.store.calendarData) {
                    this.store.calendarData[key].items.map((i) => {
                        let obj = Object.assign({}, i);
                        obj.date = this.COMMON.formatCalendarDate(
                            obj.date,
                            "YYYY-MM-DD HH:mm",
                            this.selectedTimeZone
                        );
                        //console.log(obj);
                        temp.push(obj);
                    });
                }
                temp.map((i, index) => {
                    const ind = allEvents.findIndex(
                        (j) =>
                            moment(j.date).format("YYYY-MM-DD") ==
                            moment(i.date).format("YYYY-MM-DD")
                    );
                    if (ind > -1) {
                        if (allEvents[ind].items) {
                            allEvents[ind].items.push(i);
                        } else {
                            allEvents[ind].items = [i];
                        }
                    } else {
                        allEvents.push({
                            date: moment(i.date).format("YYYY-MM-DD"),
                            items: [i],
                        });
                    }
                });
                return allEvents;
            } else {
                return [];
            }
        },
    },
    mounted() {
        const _that = this;
        $(document).on("click", function (event) {
            if (!$(event.target).closest(".filter-dropdown").length) {
                _that.dropdownMenu = false;
                //_that.showCalendar = false;
            }
        });
        $(document).on("click", function (event) {
            if (!$(event.target).closest(".custom-date").length) {
               // _that.dropdownMenu = false;
                _that.showCalendar = false;
            }
        });
        this.selectedCountries =
            this.static_vars.economicCalendarCountries[
            this.currentCountryFilter.toLowerCase()
            ];
        this.getCalendarList();
        const settings = {
            fill: "#1abc9c",
            background: "#d7dcdf",
        };
        // const sliders = document.querySelectorAll(".range-slider");
        // Array.prototype.forEach.call(sliders, (slider) => {
        //   slider.querySelector("input").addEventListener("input", (event) => {
        //     slider.querySelector(".slider__value").innerHTML = event.target.value;
        //     applyFill(event.target);
        //   });
        //   applyFill(slider.querySelector("input"));
        // });
        // function applyFill(slider) {
        //   const percentage =
        //     (100 * (slider.value - slider.min)) / (slider.max - slider.min);
        //   const bg = `linear-gradient(90deg, ${settings.fill} ${percentage}%, ${
        //     settings.background
        //   } ${percentage + 0.1}%)`;
        //   slider.style.background = bg;
        // }
    },
    components: {
        bannerSlider: bannerslider,
    },
    methods: {
        getSilderValue(value, operation) {
            if (value) {
                let data = parseFloat(`${value}`.match(/[+-]?\d+(\.\d+)?/g)[0]);
                if (operation == "Add") {
                    this.sliderRange.high = parseFloat(
                        (this.sliderRange.high + (Math.abs(data) / 100) * 30).toFixed(2)
                    );
                } else if (operation == "sub") {
                    this.sliderRange.low = parseFloat(
                        (this.sliderRange.low - (Math.abs(data) / 100) * 30).toFixed(2)
                    );
                } else {
                    let high = parseFloat(
                        (data + (Math.abs(data) / 100) * 30).toFixed(2)
                    );
                    let low = parseFloat((data - (Math.abs(data) / 100) * 30).toFixed(2));
                    if (high < low) {
                        this.sliderRange.high = low;
                        this.sliderRange.low = high;
                    } else {
                        this.sliderRange.high = high;
                        this.sliderRange.low = low;
                    }
                }
            }
        },
        formatCustomDate(date) {
            return moment(date).format("L");
        },
        toggleCalendar() {
            this.showCalendar = !this.showCalendar;
        },
        handleDate() {
            if ((this.customCalendarDate[0], this.customCalendarDate[1])) {
                this.showCalendar = false;
                this.filters.dates.from = moment(this.customCalendarDate[0]).format(
                    "YYYY-MM-DD"
                );
                this.filters.dates.to = moment(this.customCalendarDate[1]).format(
                    "YYYY-MM-DD"
                );
                this.getCalendarList();
            }
        },
        toggleFilter(key) {
            for (let k in this.filterOpen) {
                if (k == key) {
                    this.filterOpen = Object.assign(this.filterOpen, {
                        [k]: !this.filterOpen[key],
                    });
                } else {
                    this.filterOpen = Object.assign(this.filterOpen, { [k]: false });
                }
            }
        },

        clearCountries() {
            this.selectedCountries = [];
        },
        getCalendarList() {
            this.toggleFilter("all");
            const d = new Date();
            let formData = {
                filter: {
                    country: this.selectedCountries,
                    ...this.filters.dates,
                    offset: d.getTimezoneOffset()
                },
            };
            if (this.filters.impact > 0) {
                formData.filter.priority = parseFloat(this.filters.impact);
            }
            // if(this.dateChangeActive == 'recent'){
            //   this.filters.dates.from = moment().subtract(1, "day").format("YYYY-MM-DD");
            //   this.filters.dates.to = moment().add(10,'day').format("YYYY-MM-DD");
            // }
            this.store
                .getCalendar(formData, false)
                .then(() => { })
                .catch((err) => console.log(err.message));
        },
        toggleSelection(country) {
            let ind = this.selectedCountries.findIndex((i) => i === country);
            if (ind > -1) {
                this.selectedCountries.splice(ind, 1);
            } else {
                this.selectedCountries.push(country);
            }
        },
        changeDate(type) {
            this.dateChangeActive = type;
            if (type == "recent") {
                this.filters.dates.from = moment().subtract(1, "day").format("YYYY-MM-DD");
                this.filters.dates.to = moment().add(10, 'day').format("YYYY-MM-DD");
            } else if (type == "yesterday") {
                this.filters.dates.from = moment()
                    .subtract(1, "day")
                    .format("YYYY-MM-DD");
                this.filters.dates.to = moment()
                    .subtract(1, "day")
                    .format("YYYY-MM-DD");
            } else if (type == "today") {
                this.filters.dates.from = moment().format("YYYY-MM-DD");
                this.filters.dates.to = moment().format("YYYY-MM-DD");
            } else if (type == "tomorrow") {
                this.filters.dates.from = moment().add(1, "day").format("YYYY-MM-DD");
                this.filters.dates.to = moment().add(1, "day").format("YYYY-MM-DD");
            } else if (type == "week") {
                this.filters.dates.from = moment().startOf("week").format("YYYY-MM-DD");
                this.filters.dates.to = moment().endOf("week").format("YYYY-MM-DD");
            } else if (type == "nextWeek") {
                this.filters.dates.from = moment()
                    .add(1, "weeks")
                    .startOf("week")
                    .format("YYYY-MM-DD");
                this.filters.dates.to = moment()
                    .add(1, "weeks")
                    .endOf("week")
                    .format("YYYY-MM-DD");
            } else if (type == "nextMonth") {
                this.filters.dates.from = moment()
                    .add(1, "month")
                    .startOf("month")
                    .format("YYYY-MM-DD");
                this.filters.dates.to = moment()
                    .add(1, "month")
                    .endOf("month")
                    .format("YYYY-MM-DD");
            } else if (type == "prevWeek") {
                this.filters.dates.from = moment()
                    .subtract(1, "weeks")
                    .startOf("isoWeek")
                    .format("YYYY-MM-DD");
                this.filters.dates.to = moment()
                    .subtract(1, "weeks")
                    .endOf("isoWeek")
                    .format("YYYY-MM-DD");
            } else if (type == "prevMonth") {
                this.filters.dates.from = moment()
                    .subtract(1, "month")
                    .startOf("month")
                    .format("YYYY-MM-DD");
                this.filters.dates.to = moment()
                    .subtract(1, "month")
                    .endOf("month")
                    .format("YYYY-MM-DD");
            }
            this.getCalendarList();
            this.dropdownMenu = false;
        },
        selectCountriesFromTab(tab) {
            this.currentCountryFilter = tab;
            if (tab == "All") {
                let arr = [];
                for (let key in this.static_vars.economicCalendarCountries) {
                    this.static_vars.economicCalendarCountries[key].map((i) => {
                        arr.push(i);
                    });
                }
                this.selectedCountries = [...new Set(arr)];
            } else {
                this.selectedCountries = [
                    ...new Set(
                        this.static_vars.economicCalendarCountries[tab.toLowerCase()]
                    ),
                ];
            }
        },
        inSelectedCountries(country) {
            try {
                if (this.selectedCountries.includes(country)) return true;
                return false;
            } catch (error) {
                console.log(error);
            }
        },
        getTimezoneSelected(val) {
            let obj = this.static_vars.timezonesCalendar.find((i) => i.value === val);
            return obj ? obj.label : "System Default";
        },
        showAlertBox(indexOuter, index) {
            if (this.modalIndexInner == index) {
                this.modalIndexInner = -1;
                this.modalIndexOuter = -1;
            } else {
                this.modalIndexInner = index;
                this.modalIndexOuter = indexOuter;
            }
        },
        getFormattedPrice(price) {
            if (typeof price != "number") price = parseFloat(price);
            if (isNaN(price)) return 0;
            price = price.toFixed(2);
            // return price.replace(/([0-9]+(\.[0-9]+[1-9])?)(\.?0+$)/, '$1')
            let stringPrice =
                parseInt(price).toLocaleString() +
                (price.toString().indexOf(".") != -1 ? "." + price.split(".")[1] : "");
            // return stringPrice.replace(/(?:(\.\d*?[1-9]+)|\.)0*$/gm, "$1");
            return stringPrice;
        },
        setSliderValues(operation) {
            let item =
                this.store.calendarData[this.indexOuter].items[this.indexInner];
            let initialValue = item.teForecast
                ? parseFloat(`${item.teForecast}`.match(/[+-]?\d+(\.\d+)?/g)[0])
                : item.previous
                    ? parseFloat(`${item.previous}`.match(/[+-]?\d+(\.\d+)?/g)[0])
                    : 0;
            if (operation) {
                if (operation == "add") {
                    this.slider.high = parseFloat(
                        this.slider.high + (Math.abs(initialValue) / 100) * 30
                    );
                } else if (operation == "sub") {
                    this.slider.low = parseFloat(
                        this.slider.low - (Math.abs(initialValue) / 100) * 30
                    );
                }
            } else {
                this.slider = {
                    value: initialValue,
                    high: parseFloat(initialValue + (Math.abs(initialValue) / 100) * 30),
                    low: parseFloat(initialValue - (Math.abs(initialValue) / 100) * 30),
                };
            }
            this.step =
                (Math.abs(this.slider.high) + Math.abs(this.slider.low)) / 100;
        },
        toggleInner(indexOuter, index, symbol) {
            if (this.indexInner == index) {
                this.indexInner = -1;
                this.indexOuter = indexOuter;
            } else {
                this.indexOuter = indexOuter;
                this.store.calendarTickerData = {};
                this.indexInner = index;
                this.setSliderValues();
                this.store
                    .getCalendarTickerHistory({ Symbol: symbol }, true)
                    .then((response) => {
                        setTimeout(() => {
                            am5.ready(() => {
                                this.drawBarChart();
                            });
                        }, 300);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        },
        disposeRoot(id) {
            let d = am5.registry.rootElements.find((i) => i.dom && i.dom.id === id);
            d?.dispose();
        },
        drawBarChart() {
            if (
                this.store.calendarTickerData.chartdata &&
                this.store.calendarTickerData.chartdata.length
            ) {
                this.disposeRoot("tickerChart");
                var root = am5.Root.new("tickerChart");
                root.interfaceColors.set("grid", am5.color(0xffffff));

                root.setThemes([am5themes_Animated.new(root)]);

                var chart = root.container.children.push(
                    am5xy.XYChart.new(root, {
                        panX: false,
                        panY: false,
                        // wheelX: "panX",
                        // wheelY: "zoomX",
                    })
                );
                var cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
                cursor.lineY.set("visible", false);

                var xRenderer = am5xy.AxisRendererX.new(root, {});
                xRenderer.labels.template.setAll({
                    // rotation: -45,
                    centerY: am5.p50,
                    // centerX: am5.p100,
                });
                var xAxis = chart.xAxes.push(
                    am5xy.GaplessDateAxis.new(root, {
                        baseInterval: { timeUnit: "month", count: 1 },
                        gridIntervals: [{ timeUnit: "month", count: 4 }],
                        renderer: xRenderer,
                        tooltip: am5.Tooltip.new(root, {}),
                    })
                );
                var yAxis = chart.yAxes.push(
                    am5xy.ValueAxis.new(root, {
                        renderer: am5xy.AxisRendererY.new(root, {}),
                    })
                );

                var series = chart.series.push(
                    am5xy.ColumnSeries.new(root, {
                        name: "Ticker",
                        xAxis: xAxis,
                        connect: true,
                        yAxis: yAxis,
                        valueXField: "date",
                        valueYField: "value",
                        tooltip: am5.Tooltip.new(root, {
                            labelText: "{valueY}",
                        }),
                    })
                );

                // series.set("stroke", "#16C784");
                // series.set("fill", "#16C784");
                series.columns.template.adapters.add("stroke", (fill, target) => {
                    if (target._dataItem.dataContext.value > 0) {
                        return "#16C784";
                    } else {
                        return "#E88018";
                    }
                });
                series.columns.template.adapters.add("fill", (fill, target) => {
                    if (target._dataItem.dataContext.value > 0) {
                        return "#16C784";
                    } else {
                        return "#E88018";
                    }
                });

                // series.columns.template.adapters.add("fill", (fill, target) => {
                //   return "#16C784";
                // });

                var data = this.store.calendarTickerData.chartdata
                    .slice(
                        0,10
                    )
                    .reverse();
                xAxis.data.setAll(data);
                series.data.setAll(data);
                chart.zoomOutButton.set("forceHidden", true);
                series.appear(300);
                chart.appear(300, 100);
            }
        },
    },
};
</script>
<style>
#tickerChart > div {
  display: none;
}
#tickerChart > div:last-child {
  display: block !important;
}
.handle.hover {
    background-color: #11998e;
}

.vue3-slider .handle {
    background-color: #11998e;
}

.vue3-slider .tooltip {
    opacity: 1;
    font-weight: normal;
    font-size: 10px;
}

.vue3-slider {
    height: 14px;
    width: calc(100% - 34px);
    margin-left: auto;
    margin-right: auto;
}

.vue3-slider .track,
.vue3-slider .track-filled {
    border-radius: 0;
}
</style>
